<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-09 10:52:15
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-05 15:02:19
-->

<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus"
      >新增</el-button
    >
  </el-card>
  <el-card class="box data">
    <el-table :data="roles" v-loading="loading" border>
      <!-- <el-table-column label="编号" prop="id" width="100"></el-table-column> -->
      <el-table-column label="名称" prop="name" width="360"></el-table-column>
      <el-table-column label="权限">
        <template #default="item">
          <el-tag type="success" v-if="item.row.iis_allot">已配置</el-tag>
          <el-button
            size="small"
            @click="onSetFunction(item.row, app)"
            v-for="app in apps"
            :key="app.key"
            >配置[{{ app.name }}]权限</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        prop="create_at"
        width="240"
      ></el-table-column>
      <el-table-column label="操作" width="200">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)"
            >修改</el-button
          >
          <!-- <el-button type="text" class="btn-del" size="small" @click="onEdit(scope.row)"
          >删除</el-button
        > -->
          <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <edit-role :item="current" :reload="loadData"></edit-role>
  <role-function
    :item="funItem"
    :app="funApp"
    :reload="loadData"
  ></role-function>
</template>
<script>
import role_api from "@/http/role_api";
import EditRole from "./edit_role";
import RoleFunction from "./role_function.vue";
import app_api from "@/http/app_api";

export default {
  components: {
    EditRole,
    RoleFunction,
  },
  data() {
    return {
      loading: false,
      current: null,
      funItem: null,
      funApp: null,
      roles: [],
      apps: [],
    };
  },
  created() {
    this.loadData();
    app_api.get_apps().then((res) => {
      if (res.code == 0) {
        this.apps = res.data.filter((x) => x.type == 0);
      }
    });
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      role_api.get_roles().then((res) => {
        if (res.code == 0) {
          this.roles = res.data;
        }
      });
    },
    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = item ? item : {};
    },

    /**
     * 配置权限
     */
    onSetFunction(item, app) {
      this.funItem = item;
      this.funApp = app;
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("/admin/v1/role/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
  },
};
</script>