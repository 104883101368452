<!--
 * @Description: 角色权限配置
 * @Author: 琢磨先生
 * @Date: 2022-05-09 21:57:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-25 16:20:54
-->

<template>
  <el-drawer
    v-model="drawer"
    :title="title"
    direction="rtl"
    size="80%"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <el-scrollbar>
      <el-tabs v-model="activeName">
        <el-tab-pane label="菜单数据" name="menu">
          <el-table
            ref="menuTable"
            :data="menus"
            @selection-change="menuSelectionChange"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column prop="id" label="编号" width="100">
            </el-table-column>
            <el-table-column label="全称" prop="full_name"></el-table-column>
            <el-table-column label="url地址" prop="url"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="Api接口" name="api">
          <el-table
            ref="apiTable"
            :data="apis"
            @selection-change="apiSelectionChange"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column prop="id" label="编号" width="100">
            </el-table-column>
            <el-table-column label="全称" prop="full_name"></el-table-column>
            <el-table-column label="url地址" prop="url"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-scrollbar>
    <template #footer>
      <div style="flex: auto">
        <el-button @click="cancelClick" :disabled="loading">取消</el-button>
        <el-button
          type="primary"
          @click="onSubmit"
          :loading="loading"
          :disabled="loading"
          >确定</el-button
        >
      </div>
    </template>
  </el-drawer>
</template>
<script>
import function_api from "@/http/function_api";

export default {
  data() {
    return {
      drawer: false,
      loading: false,
      title: "",
      activeName: "menu",
      menus: [],
      apis: [],
    };
  },
  props: ["item", "app", "reload"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true; 
          this.title = `配置角色[${this.item.name}]的[${this.app.name}]权限`;
          this.loadFunctions();
        }
      },
    },
  },
  methods: {
    /**
     * 加载平台管理的菜单、api数据
     */
    loadFunctions() {
      function_api.get_funs(this.app.app_key).then((res) => {
        if (res.code == 0) {
          this.menus = res.data.filter((x) => x.is_menu);
          this.apis = res.data.filter((x) => !x.is_menu);
          this.loadRoleFun();
        }
      });
    },
    /**
     * 获取角色的权限数据
     */
    loadRoleFun() {
      this.$http
        .get(`admin/v1/role/funs?id=${this.item.id}&appkey=${this.app.app_key}`)
        .then((res) => {
          if (res.code == 0) {
            res.data.map((x) => {
              if (x.function.is_menu) {
                var item = this.menus.find((o) => o.id == x.function_id);
                if (item) {
                  this.$refs.menuTable.toggleRowSelection(item, true);
                }
              } else {
                item = this.apis.find((o) => o.id == x.function_id);
                if (item) {
                  this.$refs.apiTable.toggleRowSelection(item, true);
                }
              }
            });
          }
        });
    },
    /**
     * 提交保存
     */
    onSubmit() {
      var ids = [];
      if (this.checked_menus) {
        this.checked_menus.map((x) => {
          ids.push(x.id);
        });
      }
      if (this.checked_apis) {
        this.checked_apis.map((x) => {
          ids.push(x.id);
        });
      }
      this.loading = true;
      this.$http
        .post("/admin/v1/role/setfuns", {
          roleId: this.item.id,
          functionIds: ids,
        })
        .then((res) => {
          this.loading = false;
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: res.msg,
            });
          }
        });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.drawer = false;
      if (this.reload) {
        this.reload();
      }
    },
    /**
     * 选中更改
     * @param {*} values
     */
    menuSelectionChange(values) {
      this.checked_menus = values;
    },
    /**
     * 选中更改
     * @param {*} values
     */
    apiSelectionChange(values) {
      this.checked_apis = values;
    },
  },
};
</script>






















